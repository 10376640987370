
const shortenAddress = (string, chars = 8) => `${string.slice(0, chars)}...${string.slice(-chars, string.length)}`;

const formatDate = (timestamp) => {
  const date = new Date(Number(timestamp * 1000));
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

const getAmountFromInput = (input) => {
    const amountHash = input.slice(-64);
    return parseInt(amountHash);
}

const transactionTypes = {

}

const getTransactionType = (methodId) => {
    return transactionTypes[methodId];
}

export { shortenAddress, formatDate, getAmountFromInput };