<template>
  <div
    @click.stop
    :class="showWalletModal && 'active'"
    class="modal position-relative"
  >
    <div @click="closeModal" class="close-modal">×</div>
    <div class="modal-content wallet-modal">
      <h4 class="text-center mb-4">Connect Wallet</h4>
      <!-- {{type}} -->

      <template v-if="noMetamask">
        <div
          @click="installMetamask"
          class="
            metamask-button
            d-flex
            justify-content-between
            align-items-center
            p-3
          "
        >
          <span>Install MetaMask</span>
          <img :src="metamaskIcon" />
        </div>
      </template>

      <template v-else>
        <p style="border: 1px solid rgba(0, 0, 0, 0.1)" class="mt-2 mb-4 p-2">
          <small>
            By connecting a wallet, you agree to
            <router-link to="/termsofservice"
              >Ten31 Terms of Service</router-link>
            and acknowledge that you have read and understand the
            <router-link to="/protocoldisclaimer">
              Blockchain protected stock protocol disclaimer</router-link
            >.</small
          >
        </p>
        <div
          @click="metamaskSetup"
          class="
            metamask-button
            d-flex
            justify-content-between
            align-items-center
            p-3
          "
        >
          <span
            ><div v-if="metamaskAddress" class="green-dot me-1"></div>
            {{
              metamaskAddress ? "Metamask Connected" : "Connect Metamask"
            }}</span
          >
          <img :src="metamaskIcon" />
        </div>
      </template>

    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import metamaskIcon from "../assets/images/metamaskicon.svg";
import { metamaskSetup } from "../main";
export default defineComponent({
  name: "Wallet Modal",
  data: function () {
    return {
      test: "test",
      metamaskIcon: metamaskIcon,
      metamaskAddress: null,
      noMetamask: false,
    };
  },
  mounted: function () {
    if (!window.ethereum) {
      this.noMetamask = true;
    }
    this.$store.watch((state) => {
      this.metamaskAddress = state.metamaskAddress;
    });
  },
  props: {
    showWalletModal: {
      required: false,
      default: false,
    },
  },
  methods: {
    metamaskSetup() {
      metamaskSetup();
    },
    closeModal() {
      this.$emit('closeModal');
    },
    installMetamask() {
      window.open('https://metamask.io/download.html')
    }
  },
});
</script>