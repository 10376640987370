import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { ethers, BigNumber } from 'ethers';
import Maska from 'maska';
import Toaster from '@meforma/vue-toaster';
import config from './config.js';
// import Web3 from 'web3';
// import { AbiItem } from 'web3-utils';

//new abis
import companyRegistryAbi from './abis/company-ABI.json';
import identityRegistryAbi from './abis/identity-ABI.json';
import tokenRegistryAbi from './abis/token-ABI.json';
import multisigRegistryAbi from './abis/multisig-ABI.json';
import multisigAccountAbi from './abis/multisig-account-ABI.json';
import thirdPartyAbi from './abis/thirdparty-ABI.json';
import thirdPartyAccountAbi from './abis/thirdparty-account-ABI.json';
import thirdPartyManagerAbi from './abis/thirdparty-manager-ABI.json';
import erc20Abi from './abis/erc20-ABI.json';

const MAX_ALLOWANCE = ethers.BigNumber.from('0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF');

router.beforeEach((to, from, next) => {
  const metamaskAddress = store.state.metamaskAddress;
  const noIdentity = store.state.noIdentity;
  const contractError = store.state.contractError;
  if ((to.name !== 'Connect' && !metamaskAddress) || noIdentity || contractError) {
    next({ name: 'Connect' })
  } else if (!router.hasRoute(to.name as string)) {
    console.log('404');
    next({ name: '404' });
  } else {
    next();
  }
});

router.isReady().then(() => {
  store.dispatch('setGlobalLoading', false);
});

// init vue app
createApp(App).use(Toaster).use(store).use(router).use(Maska).mount('#app');

/* eslint-disable */
interface IWindow {
  ethereum: any, // eslint-disable
}

/* eslint-enable */

// window type redeclare so typescript doesn't complain
declare const window: IWindow;

// web3 provider init
let provider = new ethers.providers.Web3Provider(window.ethereum);
const signer = (new ethers.providers.Web3Provider(window.ethereum)).getSigner();

const companyEntities = {};

// init company registry contract
const companyRegistryContract = new ethers.Contract(config.companyRegistryContract, companyRegistryAbi, signer);

async function getCompanyEntitites(userAddress:string):Promise<void> {
  console.log('get company entities called');
  try {
    console.log('trying...');
    const companyCount = await companyRegistryContract.getCompanyCount();
    console.log(companyCount);
    const companies = await companyRegistryContract.getCompanies(0, companyCount.toNumber());
    const multisigContracts:string[] = [];
    for (let i = 0; i < companies[1].length; i++) {
      const entities = await companyRegistryContract.getCompanyEntities(companies[1][i]);
      console.log('ENTITIES', entities);
      companyEntities[i] = {};
      for (let j = 1; j < entities.length; j += 2) {
        switch (j) {
          case 1: {
            companyEntities[companies[1][i]].identityRegistryContract = entities[j];
            break;
          }
          case 3: {
            companyEntities[companies[1][i]].tokenRegistryContract = entities[j];
            break;
          }
          case 5: {
            companyEntities[companies[1][i]].multisigRegistryContract = entities[j];
            multisigContracts.push(entities[j]);
            break;
          }
          case 7: {
            companyEntities[companies[1][i]].thirdPartyRegistryContract = entities[j];
            break;
          }
          case 9: {
            companyEntities[companies[1][i]].blankAccountRegistryContract = entities[j];
            break;
          }
        }
      }
    }
    console.log('COMPANY ENTITIES: ', companyEntities);
    checkIdentity(userAddress);
  } catch(error:any) {
    store.dispatch('setContractError', true);
    console.log(error, error.code);
  }
}

export async function checkIdentity(userAddress:string):Promise<void> {
  let hasIdentity: boolean|unknown = false;
  const promisesArr:Promise<Record<string, boolean>>[] = [];
  for (const entity in companyEntities) {
    console.log(entity);
    const identityContract = new ethers.Contract(companyEntities[entity].identityRegistryContract, identityRegistryAbi, signer);
    promisesArr.push(
      identityContract.isUserAddressWhitelisted(userAddress)
    );
  }
  Promise.all(promisesArr).then(res => {
    hasIdentity = res.length && res.reduce((prev, current) => {
      return prev || current;
    });
    if (hasIdentity) {
      store.dispatch('setNoIdentity', false);
      getCompaniesFromContract(userAddress);
    } else {
      store.dispatch('setNoIdentity', true);
    }
  });
}

//get company and stock data from contract
export async function getCompaniesFromContract(userAddress:string, fromIndex = 0, toIndex = 10):Promise<void> {
  const companiesObj = {};
  console.log('get companies called');
  try {
    const companyCount = await companyRegistryContract.getCompanyCount();
    console.log('company count', companyCount);
    companyRegistryContract.getCompaniesData(fromIndex, companyCount, userAddress)
      .then(companyRegRes => {
        console.log('company registry companiues data', companyRegRes);
        for (let i = 0; i < companyRegRes[0].length; i++) {
          companiesObj[companyRegRes[0][i]] = {
            companyName: companyRegRes[1][i],
            companyId: companyRegRes[0][i].toNumber(),
            userWhitelisted: companyRegRes[2][i],
            tokens: {}
          }
        }
        const tokenContracts: string[] = [];
        for (const company in companyEntities) {
          tokenContracts.push(companyEntities[company].tokenRegistryContract);
        }
        tokenContracts.forEach(async tokenAddress => {
          const tempContract = new ethers.Contract(tokenAddress, tokenRegistryAbi, signer);
          const tokenData = await tempContract.getTokenData(0, 50);
          for (let i = 0; i < tokenData[0].length; i++) {
            const tempTokenContract = new ethers.Contract(tokenData[0][i], tokenRegistryAbi, signer)
            const tokenCompanyId = await tempTokenContract.getCompanyId();
            console.log(tokenCompanyId);
            companiesObj[tokenCompanyId.toNumber()].tokens[tokenData[0][i]] = {
              tokenAddress: tokenData[0][i],
              tokenName: tokenData[1][i],
              tokenSymbol: tokenData[2][i],
              tokenLogoUrl: tokenData[3][i],
              balance: 1
            };
            const balances = await tempContract.getAccountBalances(userAddress, 0, 50);
            console.log(companiesObj, balances);
            for (let j = 0; j < balances.length; j++) {
              if (!companiesObj[tokenCompanyId.toNumber()].tokens[balances[0][j]]) {
                break;
              }
              companiesObj[tokenCompanyId.toNumber()].tokens[balances[0][j]].balance = balances[1][j].toNumber();
            }
            store.dispatch('setCompanies', companiesObj);
          }
        });
      })
      .catch(error => {
        console.log(error);
        store.dispatch('setCompanyBalances', []);
        store.dispatch('setCompanies', []);
      });
  } catch (error) {
    console.log(error);
    getCompaniesFromContract(store.state.metamaskAddress, 0, 10);
  }
}

// const tokenContracts = config.tokenRegistryContracts; // ['0x11FB47E4bc55c838d05745DbbdB8115821C1Df6A', '0x5d6349497eb05f34d4B22FFFb40547621eed47af'];

// connect to metamask and save config to vuex store
export async function metamaskSetup(): Promise<void> {
  provider = new ethers.providers.Web3Provider(window.ethereum);
  console.log(window.ethereum.selectedAddress)
  if (window.ethereum.selectedAddress) {
    setTimeout(() => {
      store.dispatch('setNetworkId', window.ethereum.networkVersion);
      store.dispatch('setMetamaskAddress', window.ethereum.selectedAddress);
      store.dispatch('setChainId', window.ethereum.chainId);
      store.dispatch('setMetamaskCalled', true);
      getCompanyEntitites(window.ethereum.selectedAddress);
    }, 500);
  } else {
    const res = await window.ethereum.request({ method: 'eth_requestAccounts' });
    console.log('metamask connected', res);
    store.dispatch('setNetworkId', window.ethereum.networkVersion);
    store.dispatch('setMetamaskAddress', window.ethereum.selectedAddress);
    store.dispatch('setChainId', window.ethereum.chainId);
    store.dispatch('setMetamaskCalled', true);
    getCompanyEntitites(window.ethereum.selectedAddress);
  }
}

// get ETH balance
export async function getBalance():Promise<string> {
  const balanceProvider = new ethers.providers.Web3Provider(window.ethereum);
  const balance = await balanceProvider.getBalance('ethers.eth');
  const balanceInt = ethers.utils.formatEther(balance);
  return balanceInt;
}

export async function customTokenSend(toAddress: string, amount: string):Promise<void> {
  const contract = new ethers.Contract(config.companyRegistryContract, companyRegistryAbi, signer);
  contract.transfer(toAddress, ethers.utils.parseUnits(amount, 'ether').toHexString())
    .then((res: Record<string, string>) => {
      console.log(res);
    });
}

// metamask events
window.ethereum.on('accountsChanged', async () => {
  console.log('accunt changed');
  store.dispatch('setCompanyBalances', []);
  store.dispatch('setCompanies', []);
  store.dispatch('setNetworkId', window.ethereum.networkVersion);
  store.dispatch('setNoIdentity', false);
  store.dispatch('setContractError', false);
  store.dispatch('setMetamaskAddress', '');
  setTimeout(() => {
    router.push('/connect');
  }, 1000);
});

window.ethereum.on('chainChanged', async () => {
  store.dispatch('setCompanyBalances', []);
  store.dispatch('setCompanies', []);
  store.dispatch('setNoIdentity', false);
  store.dispatch('setContractError', false);
  store.dispatch('setMetamaskAddress', '');
  store.dispatch('setNetworkId', window.ethereum.networkVersion);
  // metamaskSetup();
  setTimeout(() => {
    router.push('/connect');
  }, 1000);
});

// let multisigContract;

//custom wait function
function wait(miliseconds):Promise<string> {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(`waited for ${miliseconds} miliseconds`);
    }, miliseconds);
  });
}

// multisig functions
export async function multisigRegistrySetup(chosenCompany = 0, userAddress:string, chosenMultisig:string = companyEntities[0].multisigContract):Promise<void> {
  console.log(chosenMultisig);
  try {
    console.log('MULT registry contract address', companyEntities[chosenCompany].multisigRegistryContract);
    const multisigRegistryContract = new ethers.Contract(companyEntities[chosenCompany].multisigRegistryContract, multisigRegistryAbi, signer);
    const accountCount = await multisigRegistryContract.getAccountCount();
    console.log('account count multisig', accountCount.toNumber());
    multisigRegistryContract.getParticipantsChildrenAccounts(window.ethereum.selectedAddress, 0, accountCount).then(res => {
      console.log('PARTICIPANT CHILDREN ACCS', res);
    });
    if (!accountCount.toNumber()) {
      console.log('no multisigs');
      store.dispatch('setMultisigContracts', []);
      return;
    }
    const accounts = (await multisigRegistryContract.getAccounts(0, accountCount)).slice(0);
    console.log('accounts', accounts);
    accounts.push([]);
    for (const account of accounts[1]) {
      await multisigRegistryContract.getIsParticipantsChildRegistered(window.ethereum.selectedAddress, account).then(res => {
        accounts[2].push(res);
      });
    }
    accounts[1] = accounts[1].filter((item, index) => {
      return accounts[2][index];
    });
    store.dispatch('setMultisigContracts', accounts);
  } catch (error) {
    console.log(error);
  }
}

export async function getVotes(multisigAccountAddress: string, tokenAddress:string):Promise<any> {
  console.log('multisig: ', multisigAccountAddress, 'token: ', tokenAddress);
  const multisigAccountContract = new ethers.Contract(multisigAccountAddress, multisigAccountAbi, signer);
  const voteCount = await multisigAccountContract.getExecuteVoteCount(tokenAddress);
  return multisigAccountContract.getExecuteVotesData(ethers.utils.getAddress(tokenAddress), 0, voteCount.toNumber());
}

export async function getVoteCount(multisigAccountAddress:string, tokenAddress:string) {
  console.log('multisig address', multisigAccountAddress);
  const multisigAccountContract = new ethers.Contract(multisigAccountAddress, multisigAccountAbi, signer);
  const voteCount = await multisigAccountContract.getExecuteVoteCount(tokenAddress);
  console.log('vote count', voteCount);
  // debugger; // eslint-disable-line no-debugger
  const votes = await multisigAccountContract.getExecuteVotesData(ethers.utils.getAddress(tokenAddress), 0, voteCount.toNumber());
  const currentTime = new Date().getTime();
  let pendingVoteCount = 0;
  for (const vote of votes) {
    console.log(vote.voteFinishTimestamp.toNumber(), Math.floor(currentTime/1000), Math.floor(currentTime/1000) < vote.voteFinishTimestamp.toNumber());
    if ((!vote.voteFinished && Math.floor(currentTime/1000) < vote.voteFinishTimestamp.toNumber())) {
      pendingVoteCount++;
    }
  }
  return {[tokenAddress]: pendingVoteCount};
}

// export async function multisigContractSetup(multisigAccountAddress:string, tokenAddress = '0x3A889784C3258dc5836Aa7615EB91c04356ea117'):Promise<void> {
//   const multisigAccountContract = new ethers.Contract(multisigAccountAddress, multisigAccountAbi, signer);
//   const voteCount = await multisigAccountContract.getExecuteVoteCount(tokenAddress);
//   return multisigAccountContract.getExecuteVotesData(tokenAddress, 0, voteCount.toNumber());
// }

export async function proposeExecution(multisigAccountAddress:string, toAddress:string, amount:string, tokenAddress:string) {
  const multisigAccountContract = new ethers.Contract(multisigAccountAddress, multisigAccountAbi, signer);
  const params = ethers.utils.defaultAbiCoder.encode(['address', 'uint256'], [toAddress, amount]);
  return multisigAccountContract.proposeExecution(tokenAddress, 'transfer(address, uint256)', params);
}

export async function getVoteThreshold(multisigAccountAddress:string):Promise<void> {
  const multisigAccountContract = new ethers.Contract(multisigAccountAddress, multisigAccountAbi, signer);
  return multisigAccountContract.getVoteTreshold();
}

export async function castVote(multisigAccountAddress:string, tokenAddress:string, voteId:number):Promise<any> {
  const multisigAccountContract = new ethers.Contract(multisigAccountAddress, multisigAccountAbi, signer);
  console.log('token address:', tokenAddress, 'vote id:', voteId);
  return multisigAccountContract.voteOnExecution(tokenAddress, voteId);
}

// third party
export async function getThirdPartyAccounts(companyId = store.state.chosenCompany):Promise<Record<any, any>> {
  console.log('third party being called, companyID:', companyId);
  const thirdPartyRegistryContract = new ethers.Contract(companyEntities[companyId].thirdPartyRegistryContract, thirdPartyAbi, signer);
  thirdPartyRegistryContract.getParticipantsChildrenAccounts('0x62287386b5bb15c96fc849c6d21b874864481ac8', 0, 20).then(res => {
    console.log('bbbbbbbbbbbbbbbbbbbbbbbb', res);
  });
  const accountCount = await thirdPartyRegistryContract.getAccountCount();
  return thirdPartyRegistryContract.getParticipantsChildrenAccounts('0x62287386b5bb15c96fc849c6d21b874864481ac8', 0, accountCount);
}

export async function getThirdPartyAccountInfo(thirdPartyAccountAddress:string):Promise<void> {
  console.log('get third party account info called, address: ', thirdPartyAccountAddress);
  const thirdPartyAccountContract = new ethers.Contract(thirdPartyAccountAddress, thirdPartyAccountAbi, signer);
  return thirdPartyAccountContract.getThirdPartyOwnerAddress();
}

export async function thirdPartySend(thirdPartyAddress:string, amount:string, toAddress:string):Promise<void> {
  console.log('THIRD PARTY SEND CALLED, ARGS: ', thirdPartyAddress, amount, toAddress);
  const thirdPartyContract = new ethers.Contract(thirdPartyAddress, thirdPartyAccountAbi, signer);
  const childAddress = (await thirdPartyContract.getAccountData())[1];
  console.log(childAddress);
  const params = ethers.utils.defaultAbiCoder.encode(['address', 'uint256'], [toAddress, amount]);
  const executeRes = await thirdPartyContract.execute(thirdPartyContract.address, 'transfer(address, uint256)', params);
  console.log(executeRes);
}

// identity functions
export async function getIdentityStatus(companyId = 0):Promise<Promise<Record<string, number>>> {
  const registryContract = new ethers.Contract(companyEntities[companyId].identityRegistryContract, identityRegistryAbi, signer);
  return registryContract.getIdentityId(store.state.metamaskAddress);
}

export async function getPersonalIdentityData(companyId = 0):Promise<Promise<Record<string, string>>> {
  const personalRegistryContract = new ethers.Contract(companyEntities[companyId].identityRegistryContract, identityRegistryAbi, signer);
  return personalRegistryContract.getPersonalIdentityData(store.state.metamaskAddress);
}

export async function getCorporateIdentityData(companyId = 0):Promise<Promise<Record<string, string>>> {
  const corporateRegistryContract = new ethers.Contract(companyEntities[companyId].identityRegistryContract, identityRegistryAbi, signer);
  return corporateRegistryContract.getCorporateIdentityData(store.state.metamaskAddress);
}

export async function editPersonalInformation(formData:string[], companyId = 0):Promise<Record<string, string>|undefined> {
  const registryContract = new ethers.Contract(companyEntities[companyId].identityRegistryContract, identityRegistryAbi, signer);
  try {
    return registryContract.editPersonalInformation(
      store.state.metamaskAddress,
      [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
      ],
      [
        formData[0],
        formData[1],
        formData[2],
        formData[3],
        formData[4],
        formData[5],
        formData[6],
        formData[7],
        formData[8],
        formData[9],
        formData[10]
      ]
    );
  } catch(error:any) {
    console.log(error, error.code);
    return error;
  }
}

// corporate information
export async function editCorporateInformation(formData:string[], companyId = 0):Promise<Record<string, unknown>> {
  const registryContract = new ethers.Contract(companyEntities[companyId].identityRegistryContract, identityRegistryAbi, signer);
  return registryContract.editCorporateInformation(
    store.state.metamaskAddress,
    [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10
    ],
    [
      formData[0],
      formData[1],
      formData[2],
      formData[3],
      formData[4],
      formData[5],
      formData[6],
      formData[7],
      formData[8],
      formData[9],
      formData[10]
    ]
  );
}

export function signNonce(nonce:string):Promise<string> {
  return signer.signMessage(nonce);
}

//IBAN VALIDATION FUNCTION
export function isValidIBANNumber(input:string) {
  const CODE_LENGTHS = {
    AD: 24,
    AE: 23,
    AT: 20,
    AZ: 28,
    BA: 20,
    BE: 16,
    BG: 22,
    BH: 22,
    BR: 29,
    CH: 21,
    CR: 21,
    CY: 28,
    CZ: 24,
    DE: 22,
    DK: 18,
    DO: 28,
    EE: 20,
    ES: 24,
    FI: 18,
    FO: 18,
    FR: 27,
    GB: 22,
    GI: 23,
    GL: 18,
    GR: 27,
    GT: 28,
    HR: 21,
    HU: 28,
    IE: 22,
    IL: 23,
    IS: 26,
    IT: 27,
    JO: 30,
    KW: 30,
    KZ: 20,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    MC: 27,
    MD: 24,
    ME: 22,
    MK: 19,
    MR: 27,
    MT: 31,
    MU: 30,
    NL: 18,
    NO: 15,
    PK: 24,
    PL: 28,
    PS: 29,
    PT: 25,
    QA: 29,
    RO: 24,
    RS: 22,
    SA: 24,
    SE: 24,
    SI: 19,
    SK: 24,
    SM: 27,
    TN: 24,
    TR: 26,
    AL: 28,
    BY: 28,
    EG: 29,
    GE: 22,
    IQ: 23,
    LC: 32,
    SC: 31,
    ST: 25,
    SV: 28,
    TL: 23,
    UA: 29,
    VA: 22,
    VG: 24,
    XK: 20,
  };
  const iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, "");
  const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    return false;
  }
  const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function(letter):string {
    return (letter.charCodeAt(0) - 55).toString();
  });
  return mod97(digits);
}

function mod97(string) {
  let checksum = string.slice(0, 2);
  let fragment;
  for (let offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
}

// // send funds
// export async function metaMaskSend(sender:string, receiver:string, strEther:string):Promise<void> {
//   const params = [{
//     from: '0x9A02b510dC47Db657589368f8E5fa49e43A5BdB6',
//     to: '0x0da6851d8e26C5038db21125C5eF8c41875cD475',
//     value: ethers.utils.parseUnits('1', 'ether').toHexString()
//   }];
//   console.log(params);
//   const transactionHash = await altProvider.send('eth_sendTransaction', params);
//   console.log(transactionHash);
// }

