<template>
  <div v-if="loading" class="loading-overlay">
    <img :src="spinner" alt="" />
  </div>
  <template v-else>
    <custom-header :type="loggedIn ? 'regular' : 'regular'" />
    <router-view />
    <custom-footer />
  </template>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import spinner from './assets/images/spinner.svg';
import config from "./config";

export default {
  name: "app",
  data: function () {
    return {
      loggedIn: false,
      loading: true,
      noMetamask: false,
      spinner: spinner
    };
  },
  components: {
    "custom-header": Header,
    "custom-footer": Footer,
  },
  beforeCreate: function () {
    // check if access token available in
    const accessToken = window.localStorage.getItem("accessToken");
    // check if access token is still valid, autologin
    this.$store.dispatch("setGlobalLoading", true);
    // if (!accessToken) {
    //   this.$store.dispatch("setLoggedIn", false);
    //   this.$store.dispatch("setGlobalLoading", true);
    // } else {
    //   fetch(`${config.API}/api/v1/check-access-token`, {
    //     headers: {
    //       Authorization: `Bearer ${accessToken}`,
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //     },
    //   })
    //     .then((res) => res.json())
    //     .then(async (data) => {
    //       if (data.message && data.message === "Unauthenticated.") {
    //         await this.$store.dispatch("setLoggedIn", false);
    //         await this.$store.dispatch("setAccessToken", null);
    //         await this.$store.dispatch("setGlobalLoading", false);
    //         window.localStorage.setItem("accessToken", "");
    //         this.$router.push("/login");
    //       } else {
    //           await this.$store.dispatch("setAccessToken", accessToken);
    //         await this.$store.dispatch("setGlobalLoading", false);
    //       }
    //     });
    // }
  },
  mounted: function () {
    this.$store.watch((state) => {
      //   this.loggedIn = state.loggedIn;
      this.loading = state.globalLoading;
    });
    // this.$store.dispatch("setGlobalLoading", false);
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "setMetamaskAddress" && state.metamaskAddress) {
        this.$toast.success("Wallet connected!");
      }
    });
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/stylesheet.scss";
</style>
