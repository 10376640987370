import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from 'vue-router'
// import Home from '../views/Home.vue';


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/guest/Home.vue'),
  },
  {
    path: '/third-party',
    name: 'Third Party',
    component: () => import('../views/guest/ThirdParty.vue')
  },
  {
    path: '/termsofservice',
    name: 'Terms',
    component: () => import('../views/guest/Terms.vue'),
  },
  {
    path: '/protocoldisclaimer',
    name: 'Disclaimer',
    component: () => import('../views/guest/Disclaimer.vue'),
  },
  {
    path: '/registry',
    name: 'Registry',
    component: () => import('../views/guest/Registry.vue'),
  },
  {
    path: '/multisig',
    name: 'Import Contract',
    component: () => import('../views/guest/Multisig.vue'),
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue'),
  },
  {
    path: '/connect',
    name: 'Connect',
    component: () => import('../views/guest/Connect.vue'),
  }
];

const router = createRouter({
  history: createWebHistory('/'),
  routes
});

export default router;
