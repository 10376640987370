import { createStore, storeKey } from 'vuex'

export default createStore({
    state: {
        loggedIn: true,
        metamaskAddress: '',
        networkId: '',
        chainId: '',
        ABIInterface: undefined,
        totalBalance: undefined,
        transactions: [],
        fauBalance: undefined,
        metamaskCalled: false,
        accessToken: '',
        globalLoading: true,
        contractCompanies: [],
        companyBalances: [],
        refreshBalances: false,
        multisigVersion: null,
        canUseMultisig: null,
        activeVotes: [],
        totalVotes: null,
        tokenTransactions: [],
        chosenCompany: 0,
        chosenToken: '',
        multisigToken: {},
        noIdentity: false,
        companies: null,
        companyEntities: {},
        multisigContracts: [],
        chosenMultisigContract: null,
        contractError: false,
        voteData: null,
        thirdPartyAccounts: [],
        chosenThirdPartyAccount: null,
    },
    mutations: {
        setMetamaskCalled(state, newValue) {
            state.metamaskCalled = newValue;
        },
        setMetamaskAddress(state, newValue) {
            state.metamaskAddress = newValue;
        },
        setNetworkId(state, newValue) {
            state.networkId = newValue;
        },
        setChainId(state, newValue) {
            state.chainId = newValue;
        },
        setABIInterface(state, newValue) {
            state.ABIInterface = newValue;
        },
        setTotalBalance(state, newValue) {
            state.totalBalance = newValue;
        },
        setTransactions(state, newValue) {
            if (!newValue.length) {
                state.transactions = newValue;
            } else {
                state.transactions = state.transactions.concat(newValue);
            }
        },
        setFauBalance(state, newValue) {
            state.fauBalance = newValue;
        },
        setLoggedIn(state, newValue) {
            state.loggedIn = newValue;
        },
        setAccessToken(state, newValue) {
            state.accessToken = newValue;
        },
        setGlobalLoading(state, newValue) {
            state.globalLoading = newValue;
        },
        setCompanyBalances(state, newValue) {
            state.companyBalances = newValue;
        },
        refreshBalances(state, newValue) {
            state.refreshBalances = newValue;
        },
        setMultisigversion(state, newValue) {
            state.multisigVersion = newValue;
        },
        setCanUseMultisig(state, newValue) {
            state.canUseMultisig = newValue;
        },
        setActiveVotes(state, newValue) {
            state.activeVotes = newValue;
        },
        setTotalVotes(state, newValue) {
            state.totalVotes = newValue;
        },
        setTokenTransactions(state, newValue) {
            state.tokenTransactions = newValue;
        },
        setChosenCompany(state, newValue) {
            state.chosenCompany = newValue;
        },
        setChosenToken(state, newValue) {
            state.chosenToken = newValue;
        },
        setMultisigToken(state, newValue) {
            state.multisigToken = newValue;
        },
        setNoIdentity(state, newValue) {
            state.noIdentity = newValue;
        },
        // new companies logic
        setCompanies(state, newValue) {
            state.companies = newValue;
        },
        setCompanyEntities(state, newValue) {
            state.companyEntities = newValue;
        },
        setMultisigContracts(state, newValue) {
            state.multisigContracts = newValue;
        },
        setChosenMultisigContract(state, newValue) {
            state.chosenMultisigContract = newValue;
        },
        setContractError(state, newValue) {
            state.contractError = newValue;
        },
        setVoteData(state, newValue) {
            state.voteData = newValue;
        },
        setThirdPartyAccounts(state, newValue) {
            state.thirdPartyAccounts = newValue;
        },
        setChosenThirdPartyAccount(state, newValue) {
            state.chosenThirdPartyAccount = newValue;
        }
    },
    actions: {
        refreshBalances({ commit }, newValue) {
            commit('refreshBalances', newValue);
        },
        setMetamaskCalled({ commit }, newValue) {
            commit('setMetamaskCalled', newValue);
        },
        setMetamaskAddress({ commit }, newValue) {
            commit('setMetamaskAddress', newValue);
        },
        setNetworkId({ commit }, newValue) {
            commit('setNetworkId', newValue);
        },
        setChainId({ commit }, newValue) {
            commit('setChainId', newValue);
        },
        setABIInterface({ commit }, newValue) {
            commit('setABIInterface', newValue);
        },
        setTotalBalance({ commit }, newValue) {
            commit('setTotalBalance', newValue);
        },
        setTransactions({ commit }, newValue) {
            commit('setTransactions', newValue);
        },
        setFauBalance({ commit }, newValue) {
            commit('setFauBalance', newValue);
        },
        setLoggedIn({ commit }, newValue) {
            commit('setLoggedIn', newValue);
        },
        setAccessToken({ commit }, newValue) {
            commit('setAccessToken', newValue);
        },
        setGlobalLoading({ commit }, newValue) {
            commit('setGlobalLoading', newValue);
        },
        setCompanyBalances({ commit }, newValue) {
            commit('setCompanyBalances', newValue);
        },
        setMultisigVersion({ commit }, newValue) {
            commit('setMultisigVersion', newValue);
        },
        setCanUseMultisig({ commit }, newValue) {
            commit('setCanUseMultisig', newValue);
        },
        setActiveVotes({ commit }, newValue) {
            commit('setActiveVotes', newValue);
        },
        setTotalVotes({ commit }, newValue) {
            commit('setTotalVotes', newValue);
        },
        setTokenTransactions({ commit }, newValue) {
            commit('setTokenTransactions', newValue);
        },
        setChosenCompany({ commit }, newValue) {
            commit('setChosenCompany', newValue);
        },
        setChosenToken({ commit }, newValue) {
            commit('setChosenToken', newValue);
        },
        setMultisigToken({ commit }, newValue) {
            commit('setMultisigToken', newValue);
        },
        setNoIdentity({ commit }, newValue) {
            commit('setNoIdentity', newValue);
        },
        setCompanies({ commit }, newValue) {
            commit('setCompanies', newValue);
        },
        setCompanyEntities({ commit }, newValue) {
            commit('setCompanyEntities', newValue);
        },
        setMultisigContracts({ commit }, newValue) {
            commit('setMultisigContracts', newValue);
        },
        setChosenMultisigContract({ commit }, newValue) {
            commit('setChosenMultisigContract', newValue);
        },
        setContractError({ commit }, newValue) {
            commit('setContractError', newValue);
        },
        setVoteData({ commit }, newValue) {
            commit('setVoteData', newValue);
        },
        setThirdPartyAccounts({ commit }, newValue) {
            commit('setThirdPartyAccounts', newValue);
        },
        setChosenThirdPartyAccount({ commit }, newValue) {
            commit('setChosenThirdPartyAccount', newValue);
        }
    },
    getters: {
        getMetamaskAddress: state => {
            return state.metamaskAddress;
        }
    },
    modules: {

    }
})
