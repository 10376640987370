<template>
  <header class="header">
    <!--  -->
    <div
      class="w-100 px-4 d-flex justify-content-between align-items-center"
      v-if="type === 'regular'"
    >
      <div class="container d-flex align-items-center justify-content-between">
        <router-link to="/" class="logo me-4">
          <img src="@/assets/images/logo-dark.svg" alt="" />
        </router-link>

        <div class="header-middle">
          <router-link :class="currentRoute === '/' ? 'active' : ''" to='/'>
            Personal
          </router-link>
          <router-link :class="currentRoute === '/third-party' ? 'active' : ''" to='/third-party'>
            Third Party
          </router-link>
          <router-link :class="currentRoute.match('multisig') ? 'active' : ''" to='/multisig'>
            Entities
          </router-link>
          <router-link :class="currentRoute.match('registry') ? 'active' : ''" to='/registry'>
            Registry
          </router-link>
        </div>

        <div class="d-flex justify-content-between align-items-center">
          <div @click="metamaskAddress ? copyAddress() : connectMetamask()" class="metamask-header me-3">
            <img :src="metamaskIcon" alt="Metamask Icon">
            <p class="d-none d-sm-block mb-0 ms-3">{{metamaskAddress ? shortenAddress(metamaskAddress) : 'Not Connected'}}</p>
          </div>

          <a
            href="#"
            :class="showMobileMenu ? 'navicon active' : 'navicon'"
            @click="showMobileMenu ? showMobileMenu = false : showMobileMenu = true"
          >
            <i></i>
            <i></i>
            <i></i>
          </a>
        </div>
      </div>
    </div>


    <!--  -->
    <div
      class="container d-flex justify-content-between align-items-center"
      v-if="type === 'minimal'"
    >
      <router-link to="/" class="logo">
        <img src="@/assets/images/logo-dark.svg" alt="" />
      </router-link>

      <nav :class="isActive ? 'nav active' : 'nav'">
        <router-link to="/profile" class="user"
          ><img src="@/assets/images/user.svg" alt="" /> Kwadwo</router-link
        >
      </nav>

      <a
        href="#"
        :class="isActive ? 'navicon active' : 'navicon'"
        @click="test(!isActive)"
      >
        <i></i>
        <i></i>
        <i></i>
      </a>
    </div>

    <!-- mobile menu -->
    <div @resize="showMobileMenu = false" :class="showMobileMenu ? 'active' : ''" class="mobile-menu d-flex flex-column align-items-center justify-content-center">
      <router-link @click="showMobileMenu = false" :class="currentRoute === '/' ? 'active' : ''" to='/'>
        Personal
      </router-link>
      <router-link @click="showMobileMenu = false" :class="currentRoute === '/third-party' ? 'active' : ''" to='/third-party'>
        Third Party
      </router-link>
      <router-link @click="showMobileMenu = false" :class="currentRoute.match('multisig') ? 'active' : ''" to='/multisig'>
        Entities
      </router-link>
      <router-link @click="showMobileMenu = false" :class="currentRoute.match('registry') ? 'active' : ''" to='/registry'>
        Registry
      </router-link>
    </div>
    <!-- end mobile menu -->

  </header>
</template>

<script lang="js">
import { defineComponent } from "vue";
import metamaskIcon from '../assets/images/metamaskicon.svg';
import copyIcon from '../assets/images/copy.svg';
import redirect from '../assets/images/redirect.svg';
import config from '../config.js';
import { shortenAddress } from '../helpers.js';
import { metamaskSetup, getBalance } from "../main.ts";
import WalletModal from '../components/WalletModal.vue';

export default defineComponent({
  name: "Header",
  data: function () {
    return {
      showMobileMenu: false,
      isActive: false,
      userMenuOpen: false,
      showWalletModal: false,
      showAccInfoModal: false,
      metamaskAddress: "",
      metamaskIcon: metamaskIcon,
      copyIcon: copyIcon,
      redirect: redirect,
      wrongNetwork: false,
      balances:[],
      accessToken:null,
      shortenAddress: shortenAddress,
      loggedIn: false,
      balance: null,
      currentRoute: '/connect'
    };
  },
  components: {
    // WalletModal,
  },
  props: {
    type: {
      type: String,
      required: false,
      // If not required, it's possible to give a default value
      default: "regular",
    },
  },
  mounted() {
    window.addEventListener("resize", this.closeMobileMenu);
    if (this.$route.name === '404') {
      this.showWalletModal = false;
    } else {
      if (!this.metamaskAddress) {
        setTimeout(() => {
          this.showWalletModal = true; //set to true when done testing
        }, 700)
      }
    }
    console.log(this.$router.currentRoute._value.fullPath)
    // let balance = getBalance();
    // balance.then(res => this.balance = res);
    this.$store.watch(state => {
        this.accessToken = state.accessToken;
        this.loggedIn = state.loggedIn;
        this.metamaskAddress = state.metamaskAddress;
        if(this.accessToken && this.type !== 'login') {
            this.getBalances();
        }
    })
   
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'refreshBalances') {
            this.getBalances();
        }
        if (mutation.type === 'setMetamaskAddress') {
            this.showWalletModal = false;
            this.metamaskAddress = state.metamaskAddress;
        }
        if (mutation.type === 'setNetworkId') {
            console.log(config.mainNetworkId, config.testNetworkId, state.networkId);
            if (state.networkId !== config.mainNetworkId && state.networkId !== config.testNetworkId) {
                this.wrongNetwork = true;
            } else {
                this.wrongNetwork = false;
            }
        }
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize",  this.closeMobileMenu);
  },
  methods: {
    closeMobileMenu() {
      this.showMobileMenu = false;
    },
    logout() {
        // window.localStorage.setItem('accessToken', '');
        // this.$store.dispatch('setLoggedIn', false);
        // this.$store.dispatch('setAccessToken', null);
        this.openUserMenu();
        this.$store.dispatch('setMetamaskAddress', '');
        this.$store.dispatch('setChosenStock', '');
        this.$store.dispatch('setChosenCompany', 'all');
        this.$store.dispatch('setTokenTransactions', []);
        this.$store.dispatch('setActiveVotes', []);
    },
    // getBalance() {
    //   getBalance();
    // },
    // getBalances() {
    //     fetch(`${config.API}/api/v1/balances`, {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Accept': 'application/json',
    //             'Authorization': 'Bearer ' + this.accessToken
    //         },
    //     })
    //     .then(res => res.json())
    //     .then(data => {
    //         console.log(data);
    //         this.balances = data?.data?.balances;
    //     })
    //     .catch(error => {
    //         console.log(error);
    //     })
    // },
    copyAddress() {
        navigator.clipboard.writeText(this.metamaskAddress);
        this.$toast.success('Address copied!');
    },
    test(tab) {
      console.log(tab);
      this.isActive = tab;
    },
    openUserMenu() {
      this.userMenuOpen = !this.userMenuOpen;
    },
    closeModal() {
        this.showWalletModal = false;
        this.showAccInfoModal = false;
    },
    connectMetamask() {
      metamaskSetup();
    }
  },
  watch: {
      $route: function(newValue) {
          this.currentRoute = newValue.fullPath;
          console.log('metamask address', this.metamaskAddress)
          if (newValue.name === '404') {
            this.showWalletModal = false;
          } else {
            if (!this.metamaskAddress) {
                setTimeout(() => {
                    this.showWalletModal = true;
                }, 700)
            }
          }
      }
  }
});
</script>
